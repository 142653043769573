
.Rating {
  display: flex;
  margin-bottom: 16px;

  &_dynamic {
    cursor: pointer;

    .Star {
      transition: 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.Star {
  margin-right: 4px;
}
