
.PageContent {
  @media screen and (max-width: 1200px) {
    width: 565px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.Ordering {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
}

.Col {
  width: calc(100% / 2 - 63px);

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin: 0 auto 24px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 12px;
  }
}

.Title {
  margin: 24px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.Arrow {
  position: inherit;
  display: inline-block;
}

.PromoCodeInfo {
  margin-top: 12px;
  opacity: 0.64;
  font-size: 12px;
  line-height: 16px;
}

.Button {
  margin-top: 45px;

  @media screen and (max-width: 1200px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    margin-top: 15px;
  }
}

.Centered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
