
.Head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 34px;
  }
}

.Title {
  // max-width: 45%;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 12px;
  }
}

.Texts {
  max-width: 45%;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: 0;
  }
}

.Text {
  white-space: break-spaces;
  font-size: 16px;
  line-height: 1.5;
}