
.Title {
  margin: 16px 0 35px;
  font-family: 'CormorantGaramond' !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 56px;
  }
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.Info {
  width: 690px;
  margin: 0 20px 50px 0;

  @media screen and (max-width: 1200px) {
    width: 75%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.SubInfo {
  width: 384px;
  margin-bottom: 100px;
  padding: 16px 24px 24px;
  border: 1px solid rgba(1,3,12,0.06);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}