
.EventCard {
  position: relative;
  display: block;
  width: 100%;
  height: 240px;
  padding: 0 5px;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;

  &:hover {
    opacity: 1 !important;

    .Image {
      transform: scale(1.03);
    }
  }

  @media screen and (max-width: 1200px) {
    height: 219px;
  }

  @media screen and (max-width: 768px) {
    height: 180px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Shadow {
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  background: linear-gradient(180deg, rgba(1, 3, 12, 0) 35.21%, #01030C 114.79%);
  border-radius: inherit;
  overflow: hidden;
}

.Image {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(1, 3, 12, 0) 35.21%, #01030C 114.79%);
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.Content {
  position: absolute;
  bottom: 0;
  padding: 28px 32px;

  div {
    color: #fff;
    font-family: 'KievitPro' !important;
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 12px 16px;
  }
}

.Row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 22px;
}

.Dates {
  margin-right: 12px;
}

.Description {
  font-size: 24px;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.2;
  }
}
