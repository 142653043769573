
/* KievitPro */
@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'KievitPro';
  src: local('KievitPro'), url('./fonts/KievitPro/KievitPro-Thin.ttf') format('truetype');
  font-weight: 100;
}

/* Source Sans Pro */

@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'), url('./fonts/SourceSansPro/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'), url('./fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'), url('./fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'), url('./fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'), url('./fonts/SourceSansPro/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'), url('./fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

/* CormorantGaramond */

@font-face {
  font-family: 'CormorantGaramond';
  src: local('CormorantGaramond'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Light.ttf') format('truetype'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: local('CormorantGaramond'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Regular.ttf') format('truetype'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: local('CormorantGaramond'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Medium.ttf') format('truetype'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: local('CormorantGaramond'),
    url('./fonts/CormorantGaramond/CormorantGaramond_SemiBold.ttf') format('truetype'),
    url('./fonts/CormorantGaramond/CormorantGaramond_SemiBold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: local('CormorantGaramond'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Bold.ttf') format('truetype'),
    url('./fonts/CormorantGaramond/CormorantGaramond_Bold.woff2') format('woff2');
  font-weight: 700;
}


* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: SourceSansPro;
  color: #01030C;
}

html, #root {
  height: -webkit-fill-available;
  /* height: 100%; */
}

#root {
  position: relative;
}

body {
  height: 100%;
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:not(button):hover, button:hover {
  transition: 0.3s;
  opacity: 0.7;
}

button {
  cursor: pointer;
  border: none;
  font-size: 18px;
  line-height: 28px;
}

button:disabled {
  background: rgba(1,3,12,0.08);
  color: rgba(1,3,12,0.48);
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  line-height: 1.5;
}

.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1) !important;
}

.MuiFormLabel-filled {
  color: rgba(1,3,12,0.48) !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.MuiInputBase-input {
  padding: 12px 0 15px !important;
}

.MuiFormControlLabel-root {
  margin-right: 31px !important;
}

.slick-active button:before {
  opacity: 1 !important;
}

.slick-track {
  margin: 0;
}