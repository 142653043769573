.Footer {
	margin-top: auto;
}

.Row {
	display: flex;
	justify-content: space-between;

	&_withPaddingRight {
		padding-right: 80px;

		@media screen and (max-width: 1200px) {
			padding-right: 0;
		}
	}

	&_withBorder {
		border-top: 1px solid rgba(1, 3, 12, 0.08);
	}

	&_alignCenter {
		align-items: center;
	}

	&_socials {
		height: 56px;

		.Link {
			margin-right: 24px;
			margin-bottom: 0;

			a {
				font-size: 14px;
				line-height: 22px;
				color: rgba(1, 3, 12, 0.8);
			}

			&:last-child {
				margin-right: 0;
			}
		}

		@media screen and (max-width: 1200px) {
			height: 73px;
		}
	}

	&_copyright {
		padding-top: 20px;
		padding-bottom: 31px;

		@media screen and (max-width: 1200px) {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding-top: 15px;

			.Link {
				margin-bottom: 0;
				margin-right: 20px;
				font-size: 14px;
				line-height: 22px;

				&:last-child {
					margin-right: 0;
				}
			}

			.Rights {
				margin: 16px 0 24px;
				font-size: 14px;
				line-height: 22px;
			}
		}

		@media screen and (max-width: 768px) {
			.Link {
				margin-bottom: 8px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&_developedBy {
		display: flex;
		align-items: center;
		justify-content: center;

		a {
			display: flex;
		}
	}
}

.DropdownListWrapper {
	width: 100%;
}
.DropdownList {
	margin-bottom: 20px;
	padding-bottom: 32px;
	width: 100%;
	border-bottom: 1px solid rgba(1, 3, 12, 0.08);
}

.Rights {
	opacity: 0.48;
	font-size: 14px;
	line-height: 22px;
}

.Col {
	padding: 32px 0;
}

.Title {
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 20px;
	line-height: 1.5;
}

.Link {
	margin-bottom: 12px;
	cursor: pointer;
}

.Chat {
	transition: 0.3s;

	&:hover {
		opacity: 0.6;
	}
}

.TimeOfWork {
	font-size: 16px;
	line-height: 1.5;
	color: #01030c;
	opacity: 0.56;

	@media screen and (max-width: 1200px) {
		max-width: 170px;
	}
}

.Socials {
	display: flex;
	align-items: center;
}

.Social {
	height: 20px;
	margin-right: 20px;
}

.CopyRight {
	color: #01030c;
	font-size: 14px;
	line-height: 22px;
	opacity: 0.48;
}

.DevelopedBy {
	padding-left: 16px;
	font-size: 14px;
	line-height: 22px;
	opacity: 0.64;
}

.List {
	width: 100%;

	@media screen and (max-width: 576px) {
		padding-top: 10px;
	}
}

.Point {
	display: inline-block;
	width: 6px;
	height: 6px;
	margin: 0 8px 1px 0;
	background: #cf086e;
	border-radius: 50%;
}
