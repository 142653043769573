.SuccessChangePassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #01030C;
    font-family: 'KievitPro', sans-serif;
    text-align: center;
    font-style: normal;
    padding-top: 32px;
  }

  &__subtitle {
    max-width: 384px;
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #01030C;
    opacity: 0.56;
    padding-top: 12px;
    padding-bottom: 48px;
  }
}

.ChangePassword {
  width: 420px;
  max-width: 100%;
  margin: 30px auto 0;
}

.Title {
  margin-bottom: 12px;
  text-align: center;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    font-size: 36px;
    line-height: 44px;
  }
}

.SubTitle {
  margin-bottom: 36px;
  text-align: center;
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 24px;
}

.Link {
  margin-top: -12px;
  margin-bottom: 10px;
  color: #CF086E;
  font-size: 14px;
  line-height: 22px;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}
